define("discourse/plugins/discourse-ai/discourse/components/ai-helper-custom-prompt", ["exports", "@glimmer/component", "@ember/helper", "@ember/modifier", "@ember/object", "discourse/components/d-button", "discourse/helpers/with-event-value", "discourse-common/helpers/i18n", "truth-helpers/helpers/not", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _modifier, _object, _dButton, _withEventValue, _i18n, _not, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiHelperCustomPrompt extends _component.default {
    sendInput(event1) {
      if (event1.key !== "Enter") {
        return;
      }
      return this.args.submit(this.args.promptArgs);
    }
    static #_ = (() => dt7948.n(this.prototype, "sendInput", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="ai-custom-prompt">
    
          <input
            {{on "input" (withEventValue (fn (mut @value)))}}
            {{on "keydown" this.sendInput}}
            value={{@value}}
            placeholder={{i18n
              "discourse_ai.ai_helper.context_menu.custom_prompt.placeholder"
            }}
            class="ai-custom-prompt__input"
            type="text"
            autofocus="autofocus"
          />
    
          <DButton
            @icon="discourse-sparkles"
            @action={{fn @submit @promptArgs}}
            @disabled={{not @value.length}}
            class="ai-custom-prompt__submit btn-primary"
          />
        </div>
      
    */
    {
      "id": "P6muo3ex",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"ai-custom-prompt\"],[12],[1,\"\\n\\n      \"],[11,\"input\"],[16,2,[30,1]],[16,\"placeholder\",[28,[32,0],[\"discourse_ai.ai_helper.context_menu.custom_prompt.placeholder\"],null]],[24,0,\"ai-custom-prompt__input\"],[24,\"autofocus\",\"autofocus\"],[24,4,\"text\"],[4,[32,1],[\"input\",[28,[32,2],[[28,[32,3],[[28,[31,0],[[30,1]],null]],null]],null]],null],[4,[32,1],[\"keydown\",[30,0,[\"sendInput\"]]],null],[12],[13],[1,\"\\n\\n      \"],[8,[32,4],[[24,0,\"ai-custom-prompt__submit btn-primary\"]],[[\"@icon\",\"@action\",\"@disabled\"],[\"discourse-sparkles\",[28,[32,3],[[30,2],[30,3]],null],[28,[32,5],[[30,1,[\"length\"]]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@value\",\"@submit\",\"@promptArgs\"],false,[\"mut\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-helper-custom-prompt.js",
      "scope": () => [_i18n.default, _modifier.on, _withEventValue.default, _helper.fn, _dButton.default, _not.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiHelperCustomPrompt;
});